import * as React from 'react';
import {useState} from 'react';
import {Alert, Checkbox, CircularProgress, FormControlLabel, Grid, TextField} from "@mui/material";
import LoginRequestDto from "../../dto/LoginRequestDto";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import FormItem from "../form-item/FormItem";
import {LoadingItem} from "../loading-item/LoadingItem";
import {postLogin} from "../../api/api";
import {useDispatch} from "react-redux";
import TokenDto from "../../dto/TokenDto";
import {setAuthData, useFetchIsOnlineQuery} from "../../store";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";


const LoginForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {t, i18n} = useTranslation();
    const {data: isOnline} = useFetchIsOnlineQuery();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const loginData = data as LoginRequestDto;
        postLogin(loginData).then((tokenDto: TokenDto) => {
            dispatch(setAuthData(tokenDto));
            navigate("/");
        }).catch((err) => {
            setError(err);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{minHeight: "100vh"}}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={11} sm={8} md={4}>
                    <FormItem>
                        <Typography variant="h4" gutterBottom>
                            {t('Login')}
                        </Typography>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <TextField
                                fullWidth
                                defaultValue="" {...register("username", {required: true})}
                                label={t('Username')}
                                helperText={errors.username && t('Field is required')}
                                variant="outlined"
                                required
                            />
                            <TextField
                                fullWidth
                                label={t('Password')}
                                type="password"
                                {...register("password", {required: true})}
                                helperText={errors.password && t('Field is required')}
                                variant="outlined"
                                required
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputProps={{'aria-label': 'Remember?'}}
                                        {...register("remember", {required: true})}
                                        defaultChecked
                                    />
                                }
                                label="Remember Me"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                disabled={!isOnline}>
                                {t('Login')}
                            </Button>
                            {isLoading && (<LoadingItem><CircularProgress/></LoadingItem>)}
                            {error && (<Alert severity="error">{t("Login error! Message: ") + error}</Alert>)}
                        </Box>
                    </FormItem>
                </Grid>
            </form>
        </Grid>
    )
}

export default LoginForm;
